import React from "react";
import Container from "components/ui/Container";
import TitleSection from "components/ui/TitleSection";
import "./custom.scss";

import * as Styled from "./styles";

const CertificateList = () => {
  return (
    <Container section>
      <TitleSection title=" " subtitle="Certifications:" center />

      <p>&nbsp;</p>

      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <tr>
          <th
            style={{
              border: "1px solid #ed64a6",
              textAlign: "center",
              padding: "8px",
            }}
          >
            Certification
          </th>
          <th
            style={{
              border: "1px solid #ed64a6",
              textAlign: "center",
              padding: "8px",
            }}
          >
            Institute
          </th>
          <th
            style={{
              border: "1px solid #ed64a6",
              textAlign: "center",
              padding: "8px",
            }}
          >
            Certifying Body
          </th>
        </tr>
        <tr>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            Machine Learning
          </td>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            Stanford University{" "}
          </td>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            Coursera, USA
          </td>
        </tr>
        <tr>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            Applied Cryptography
          </td>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            Princeton University
          </td>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            Coursera, USA
          </td>
        </tr>
        <tr>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            AWS Cloud Architect
          </td>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            AWS Cloud
          </td>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            AWS
          </td>
        </tr>
        <tr>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            Data Analyst Specialist
          </td>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            AWS Cloud
          </td>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            AWS
          </td>
        </tr>
        <tr>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            Solution Architect
          </td>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            Azure Cloud
          </td>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            Microsoft
          </td>
        </tr>
        <tr>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            Blockhain Developer
          </td>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            IBM Blockchain
          </td>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            IBM
          </td>
        </tr>
        <tr>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            Blockchain Architect
          </td>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            Altoros
          </td>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            Hyperledger Blockchain
          </td>
        </tr>
        <tr>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            ITIL Expert
          </td>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            EXIN
          </td>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            UK
          </td>
        </tr>
        <tr>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            Circular Economy
          </td>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            TU Delft
          </td>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            The Netherlands
          </td>
        </tr>
        <tr>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            Neo4j Graph Data Science
          </td>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            Neo4j
          </td>
          <td
            style={{
              border: "1px solid #ed64a6",
              textAlign: "left",
              padding: "8px",
            }}
          >
            Online
          </td>
        </tr>
      </table>
    </Container>
  );
};

export default CertificateList;
