import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import CertificateList from 'components/Certifications';

const Certifications: React.FC = () => {
  return (
    <Layout>
      <SEO title="Certifications" />
      <CertificateList />
    </Layout>
  );
};

export default Certifications;
